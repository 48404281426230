import { Disclosure } from "@headlessui/react";
import { graphql } from "gatsby";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import LeadFormWithAPI from "../components/LeadFormWithAPI";
import VideoComponent from "../components/VideoComponent";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { cardSliderSettings } from "../constants";
import "../styles/page/landing/new.scss";
import CustomLink from "../components/CustomLink";

const ToolkitSlide = ({ slide }) => {
  return (
    <div className="flex-1">
      <section className="flex flex-col sm:flex-row gap-[16px] lg:gap-[27px] w-full">
        <section className="flex-1 w-auto sm:w-[780px]">
          <VideoComponent
            key={slide?.slider_video?.url}
            loop
            autoPlay
            muted
            className="rounded-2.5xl"
          >
            <source src={slide?.slider_video?.url} type="video/mp4" />
          </VideoComponent>
        </section>
        <section className="flex gap-2 lg:gap-[15px]">
          <section className="w-[20px] lg:w-[33px] h-[20px] lg:h-[33px]">
            <img
              src={slide?.heading_icon?.url}
              alt={slide?.heading_icon?.alt || "img"}
              loading="lazy"
              className="w-auto h-auto"
              width={0}
              height={0}
            />
          </section>
          <section>
            <h6 className="text-[17px] lg:text-[25px] leading-[20.9px] lg:leading-[30.95px] tracking-[-0.05em] text-blue-1100 font-medium">
              {slide?.slider_heading?.text}
            </h6>
            <section className="mt-[18px] lg:mt-[27px] max-w-[230px] lg:max-w-[340px]">
              <div
                className="text-sm lg:text-lg lg:leading-[24.57px] data_list toolkil_slider_description"
                dangerouslySetInnerHTML={{
                  __html: slide?.slider_description?.html,
                }}
              />
            </section>
          </section>
        </section>
      </section>
    </div>
  );
};

const FaqItem = ({ title, description }) => {
  return (
    <div className="faq_item">
      <Disclosure>
        <Disclosure.Button className="text-left w-full text-sm md:text-[15px] py-[17px] px-2 md:px-4 xl:px-6 flex gap-[20px] justify-between items-center">
          <span>{title}</span>
          <svg
            className="w-[18px] md:w-[25px] h-[18px] md:h-[25px] flex-shrink-0"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.4861 0.0703125H13.5398C13.7223 0.0703125 13.8136 0.161589 13.8136 0.344141V24.4411C13.8136 24.6236 13.7223 24.7149 13.5398 24.7149H11.4861C11.3035 24.7149 11.2122 24.6236 11.2122 24.4411V0.344141C11.2122 0.161589 11.3035 0.0703125 11.4861 0.0703125Z"
              fill="#989898"
            />
            <path
              d="M1.01211 11.0919H24.0137C24.1963 11.0919 24.2876 11.1832 24.2876 11.3657V13.4195C24.2876 13.602 24.1963 13.6933 24.0137 13.6933H1.01211C0.829558 13.6933 0.738281 13.602 0.738281 13.4195V11.3657C0.738281 11.1832 0.829558 11.0919 1.01211 11.0919Z"
              fill="#989898"
            />
          </svg>
        </Disclosure.Button>
        <Disclosure.Panel className="text-left text-sm md:text-[15px] text-gray-500 py-[17px] px-6 ">
          {description}
        </Disclosure.Panel>
      </Disclosure>
    </div>
  );
};

const AiAssistedSlider = ({ _data, ai_assisted_annotation_section_slider }) => {
  const [aiAssisted, setAiAssisted] = useState(0);

  return (
    <article className="relative z-10 section_container -mt-40 md:mt-0 mx-auto !max-w-[1531px] ">
      <section className="px-4 xl:px-0 my-[60px] lg:min-h-[709px] card_bg pt-[22px] pb-[40px] md:py-[42px] block sm:grid grid-cols-12 gap-[35px] xl:gap-[100px]">
        <section className="col-span-full lg:col-span-4 max-w-max lg:max-w-[435px] mx-auto xl:pl-[80px]">
          <h6 className="text-xl lg:text-[32px] lg:leading-[38.73px] tracking-[-0.05em] text-blue-1100 font-bold">
            {_data?.ai_assisted_annotation_section_heading?.text}
          </h6>
          <p className=" text-base leading-[24.57px] lg:mt-4">
            {_data?.ai_assisted_annotation_section_description?.text}
          </p>
          <section className="grid grid-cols-2 lg:grid-cols-1 gap-[6px] md:gap-[9px] mt-[30px]">
            {ai_assisted_annotation_section_slider?.items?.map(
              (item, index) => (
                <section
                  key={index}
                  onClick={() => {
                    setAiAssisted(index);
                  }}
                  className={`col-span-1 py-[6px] px-[8px] rounded-[5.7575px] outline-2 outline outline-gray-1400 bg-[#fbfbfb] text-[9px] sm:text-sm lg:text-[20px] font-medium leading-[12.28px] sm:leading-5 lg:leading-[27.3px] text-[#979797] hover:bg-[#fbfbfb] hover:text-[#979797] hover:outline-[#8082FF] cursor-pointer ${
                    aiAssisted === index &&
                    "!bg-[#8082FF] !text-white !outline-none"
                  }`}
                >
                  {item?.slide_label?.text}
                </section>
              )
            )}
          </section>
        </section>
        <section className="mt-4 col-span-full lg:col-span-8 sm:mt-0">
          <VideoComponent
            loop
            autoPlay
            muted
            key={
              ai_assisted_annotation_section_slider?.items[aiAssisted]
                ?.slide_video?.url
            }
            playsInline
            className="row-start-1 row-end-4"
          >
            <source
              src={
                ai_assisted_annotation_section_slider?.items[aiAssisted]
                  ?.slide_video?.url
              }
              type="video/webm"
            />
          </VideoComponent>
        </section>
      </section>
    </article>
  );
};

const NestedLandingPage = ({ data, location }) => {
  const _data =
    data?.allPrismicNestedLandingPage?.nodes &&
    data?.allPrismicNestedLandingPage?.nodes[0]
      ? data?.allPrismicNestedLandingPage?.nodes[0]?.data
      : {};

  const [brands] = _data?.body?.filter(
    (v) => v.slice_type === "trusted_brands_list"
  ) || [[]];

  const [ai_assisted_annotation_section_slider] = _data?.body?.filter(
    (v) => v.slice_type === "ai_assisted_annotation_section_slider"
  ) || [[]];

  const [toolkit_section_slider] = _data?.body?.filter(
    (v) => v.slice_type === "toolkit_section_slider"
  ) || [[]];

  const [testimonial] = _data?.body?.filter(
    (v) => v.slice_type === "testimonial"
  );

  const [faq_list] = _data?.body?.filter((v) => v.slice_type === "faq_list");
  const [activeToolkit, setActiveToolkit] = useState(0);

  return (
    <Layout location={location}>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          hreflang="en"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          hreflang="en"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <article className="max-w-6xl px-4 pt-32 pb-10 mx-auto space-y-6 text-center sm:px-6 md:pt-40 md:pb-20">
        <p className="text-base text-purple-500 leading-[136.52%]">
          {_data?.page_label?.text}
        </p>
        <div
          className="mb-2 lg:text-[50px] md:text-[35px] text-[25px] leading-9 sm:leading-[50px]   smaller_heading_gradient_choose_encord font-semibold"
          dangerouslySetInnerHTML={{
            __html: _data?.page_heading?.html,
          }}
        />

        <p className="mx-auto pt-2 space-y-4 check_list ont-inter max-w-2xl lg:max-w-3xl text-base lg:text-xl 2xl:text-2xl leading-[25px] lg:leading-[35px]">
          {_data?.page_description?.text}
        </p>

        <section className="flex flex-col items-center">
          <LeadFormWithAPI pageName={"new landing page"} location={location} />
          <p className="mt-2 text-lg italic text-gray-600 dark:text-gray-400">
            Part of an academic institution?{" "}
            <CustomLink
              target="_blank"
              className="text-purple-400 underline"
              to="/academic-application/"
            >
              Apply for access here.
            </CustomLink>
          </p>
          {_data?.main_page_video?.url ? (
            <VideoComponent
              width="100%"
              autoPlay={true}
              preload="auto"
              controls={false}
              loop
              muted
              className="mt-2 md:mt-[34px] xl:w-[1358px] xl:h-[644px] object-cover rounded-md"
            >
              <source src={_data?.main_page_video?.url} type="video/mp4" />
            </VideoComponent>
          ) : (
            <img
              src={_data?.main_image?.src}
              alt={_data?.main_image?.alt || "img"}
              className="mt-[100px] w-[720px] h-[342px] object-cover rounded-md"
              loading="lazy"
              width={0}
              height={0}
            />
          )}
        </section>
      </article>

      <article className="linear_background">
        <section className="section_container mx-auto py-9.5 space-y-[16px]">
          <section>
            <p className="text-center text-sm text-blue-1200   tracking-[0.07em]">
              {"Trusted by pioneering AI teams - from startups to enterprise"}
            </p>
          </section>
          <section className="grid grid-cols-2 gap-8 md:grid-cols-8">
            {brands?.items?.map((item, index) => {
              return (
                <div
                  key={`brand_${index}`}
                  className="flex justify-center col-span-1 md:col-span-2 lg:col-span-1"
                >
                  <img
                    src={item?.brand_logo?.url}
                    alt={item?.brand_logo?.alt || "img"}
                    className={`${"object-scale-down h-auto w-auto"} img_grayscale`}
                    loading="lazy"
                    width={0}
                    height={0}
                  />
                </div>
              );
            })}
          </section>
        </section>
      </article>

      <article className="pb-56 pt-16 md:py-[80px] lg:py-[150px] relative overflow-hidden text-white bg-purple-1000">
        <section className="absolute bottom-0 right-0">
          {/* <GroupEllipses /> */}
          <img
            src="https://encord.cdn.prismic.io/encord/29fb3307-d737-4031-a27c-3e2ead7eb2ad_group-ellipses.svg"
            alt="group-ellipses"
            loading="lazy"
            className="w-auto h-auto"
            width={0}
            height={0}
          />
        </section>
        <section className="z-10 mx-auto section_container">
          <section className="text-center ">
            <h2
              className="heading_gradient heading_gradient_p"
              dangerouslySetInnerHTML={{
                __html: _data?.testimonial_section_heading?.html,
              }}
            ></h2>
            <p className="mt-[24px] text-base sm:text-[20px] md:text-[30px] sm:leading-7 md:leading-[32px]">
              {_data?.testimonial_section_description?.text}
            </p>
          </section>

          <section className="mt-[107px] px-[20px]">
            <Slider {...cardSliderSettings}>
              {testimonial?.items?.map((value, index) => {
                return (
                  <div
                    key={index}
                    className="min-h-[263px] mr-5 user_card px-6 py-5 !flex flex-col justify-between bg-white"
                  >
                    <p className="text-base text-purple-1300 line-clamp-5">
                      {value?.comment?.text}
                    </p>
                    <section className="flex items-center justify-between mt-10">
                      <section className="text-purple-1300">
                        <p className="text-base font-semibold leading-7 line-clamp-1">
                          {value?.name?.text}
                        </p>
                        <p className="text-sm leading-6 mt-[11px] line-clamp-2">
                          {value?.role?.text}
                        </p>
                      </section>
                      <img
                        src={value?.avatar?.url}
                        alt="profile img"
                        width={56}
                        height={56}
                        loading="lazy"
                      />
                    </section>
                  </div>
                );
              })}
            </Slider>
          </section>
        </section>
      </article>
      <AiAssistedSlider
        _data={_data}
        ai_assisted_annotation_section_slider={
          ai_assisted_annotation_section_slider
        }
      />

      <article className="section_container my-[60px] mx-auto py-[42px] flex flex-col md:flex-row gap-[35px] xl:gap-[52px]">
        <section className="max-h-[406px] lg:max-h-[100%]">
          <img
            src={_data?.workflow_section_image?.url}
            loading="lazy"
            alt={_data?.workflow_section_image?.alt || "Section"}
            className="w-full h-full"
            width={0}
            height={0}
          />
        </section>
        <section className="max-w-max lg:max-w-[469px] mx-auto pl-[20px] xl:pl-[80px]">
          <h6 className="text-xl lg:text-[32px] lg:leading-[38.73px] tracking-[-0.05em] text-blue-1100 font-bold">
            {_data?.workflow_section_heading?.text}
          </h6>
          <p className="text-base leading-[24.57px] mt-4">
            {_data?.workflow_section_description?.text}
          </p>

          <p className="text-sm sm:text-base lg:text-xl leading-5 lg:leading-7 mt-[18px] lg:mt-20 font-bold text-purple-500">
            Learn more
          </p>
        </section>
      </article>

      <article className="section_container my-[60px] !max-w-[1531px] mx-auto ">
        <section className="px-4 xl:px-0 toolkit_card_bg py-6  md:py-[42px]">
          <section className="text-center max-w-[561px] mx-auto space-y-3">
            <h6 className="text-xl lg:text-[32px] lg:leading-[38.73px] tracking-[-0.05em] text-blue-1100 font-bold">
              {_data?.toolkit_section_heading?.text}
            </h6>
            <p className="text-base leading-[24.57px] tracking-[0.07em]">
              {_data?.toolkit_section_description?.text}
            </p>
            {/* <LeadFormWithAPI pageName={"new landing page"} location={location} /> */}
          </section>
          <section className="grid grid-cols-12 justify-center items-center gap-[27px] mt-[42px] mx-auto">
            <section className="ml-0 lg:ml-auto col-span-full lg:col-span-1 xl:col-span-2 grid grid-cols-3 xs:flex flex-row items-end lg:flex-col justify-center lg:justify-start gap-2 sm:gap-4 lg:gap-5 xl:gap-[37px] ">
              {toolkit_section_slider?.items?.map((icon, index) => (
                <section
                  key={index}
                  onClick={() => {
                    setActiveToolkit(index);
                  }}
                  className={`${
                    activeToolkit === index && "active_toolkit"
                  } group mx-auto col-span-1 flex-shrink-0  toolkit_item outline-[1px] cursor-pointer outline outline-gray-2200 rounded-md w-[59px] h-[59px] flex justify-center items-center`}
                >
                  <img
                    className={`${
                      index !== activeToolkit ? "block" : "hidden"
                    } group-hover:hidden h-auto w-auto`}
                    src={icon?.slide_icon?.url}
                    alt={icon?.slide_icon?.alt || "img"}
                    loading="lazy"
                    width={0}
                    height={0}
                  />

                  <img
                    className={`${
                      index === activeToolkit ? "block" : "hidden"
                    } group-hover:block h-auto w-auto`}
                    src={icon?.hover_icon?.url}
                    alt={icon?.hover_icon?.alt || "img"}
                    loading="lazy"
                    width={0}
                    height={0}
                  />
                </section>
              ))}
            </section>
            <section className="col-span-full lg:col-span-11 xl:col-span-10">
              <ToolkitSlide
                slide={toolkit_section_slider?.items[activeToolkit]}
              />
            </section>
          </section>
        </section>
      </article>

      <article className="py-[86px] px-5 md:py-[150px] relative  overflow-hidden">
        <section className="absolute inset-0 bg-purple-1000 -z-20" />
        <section className="absolute -bottom-10 md:bottom-0 -right-96 md:-right-60 lg:right-0 -z-10">
          <img
            src="https://images.prismic.io/encord/567d7939-a847-4c3e-99e4-e9f8057390c6_3D+Shape+Hero+Image+-+Glow+Effect+-+Dark+Theme+-+Dark+Theme+With+glass+6+-+Large+2+1.png?auto=compress,format"
            alt="3d modal"
            loading="lazy"
            className="min-w-[1290px] w-auto h-auto"
            width={0}
            height={0}
          />
        </section>
        <section className="mx-auto text-center ">
          <h2 className="max-w-[1241px] mx-auto text-[36px] md:text-[44px] lg:text-[80px] text-white leading-11 md:leading-[53.25px] lg:leading-[96.82px] tracking-[-0.03em] font-semibold">
            {_data?.cta_heading?.text}
          </h2>

          <section className="max-w-[710px] mx-auto">
            <p className=" mb-[50px] text-xl text-white leading-6 mt-9 tracking-[-0.025em]">
              {_data?.cta_description?.text}
            </p>
            <LeadFormWithAPI
              formPosition="center"
              placeholder="Your work email"
            />
          </section>
        </section>

        <section className="faq_card px-4 md:px-10 lg:px-16 py-8 md:py-14 lg:py-20 max-w-[1415px]">
          <h4>{_data?.faq_section_heading?.text}</h4>

          <p className="text-purple-1000 max-w-[533px] mt-[22px] md:mt-[44px] text-sm md:text-base leading-[24.57px]">
            {_data?.faq_section_description?.text}
          </p>

          <section className="mt-[20px] grid grid-cols-1 lg:grid-cols-2 gap-[18px] items-start">
            {faq_list?.items?.map((faq, index) => {
              return (
                <FaqItem
                  title={faq?.question?.text}
                  description={faq?.answer?.text}
                />
              );
            })}
          </section>

          <p className="text-sm md:text-[17px] md:leading-[23.93px] mt-10 text-white">
            {_data?.faq_bottom_description?.text}
          </p>
        </section>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query PrismicNestedLandingPage($uid: String!) {
    allPrismicNestedLandingPage(filter: { uid: { eq: $uid } }) {
      nodes {
        data {
          ai_assisted_annotation_section_description {
            html
            text
          }
          page_label {
            html
            text
          }
          ai_assisted_annotation_section_heading {
            html
            text
          }
          body {
            ... on PrismicNestedLandingPageDataBodyAiAssistedAnnotationSectionSlider {
              id
              slice_type
              items {
                slide_image {
                  alt
                  url
                }
                slide_label {
                  html
                  text
                }
                slide_video {
                  url
                }
              }
            }
            ... on PrismicNestedLandingPageDataBodyFaqList {
              id
              slice_type
              items {
                answer {
                  html
                  text
                }
                question {
                  html
                  text
                }
              }
            }
            ... on PrismicNestedLandingPageDataBodyTestimonial {
              id
              slice_type
              items {
                avatar {
                  alt
                  url
                }
                comment {
                  html
                  text
                }
                name {
                  html
                  text
                }
                role {
                  html
                  text
                }
              }
            }
            ... on PrismicNestedLandingPageDataBodyToolkitSectionSlider {
              id
              slice_type
              items {
                heading_icon {
                  alt
                  url
                }
                hover_icon {
                  alt
                  url
                }
                slide_icon {
                  alt
                  url
                }
                slider_video {
                  url
                }
                slider_description {
                  html
                  text
                }
                slider_heading {
                  text
                }
              }
            }
            ... on PrismicNestedLandingPageDataBodyTrustedBrandsList {
              id
              slice_type
              items {
                brand_logo {
                  alt
                  url
                }
              }
            }
          }
          cta_description {
            text
          }
          cta_heading {
            text
          }
          faq_section_description {
            text
          }
          faq_bottom_description {
            text
          }
          faq_section_heading {
            text
          }
          main_image {
            alt
            url
          }
          main_page_video {
            url
          }
          meta_description {
            text
          }
          meta_title {
            text
          }
          page_description {
            text
          }
          page_heading {
            html
            text
          }
          testimonial_section_description {
            text
          }
          testimonial_section_heading {
            html
            text
          }
          toolkit_section_heading {
            text
          }
          toolkit_section_description {
            text
          }
          workflow_section_description {
            text
          }
          workflow_section_heading {
            text
          }
          workflow_section_image {
            alt
            url
          }
          workflow_section_tags {
            html
            text
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const _data =
    data?.allPrismicNestedLandingPage &&
    !!data.allPrismicNestedLandingPage?.nodes.length &&
    data.allPrismicNestedLandingPage?.nodes[0]?.data;
  return (
    <SEO
      title={_data?.meta_title?.text}
      description={_data?.meta_description?.text}
    />
  );
};

export default NestedLandingPage;
